import React from 'react'
import { withTheme } from 'styled-components'
import { Link } from 'gatsby'
import { SwiperSlide } from 'swiper/react'
import { StaticImage } from 'gatsby-plugin-image'

import Carousel from 'components/Carousel'

import {
  Container,
  Title,
  Description,
  Card,
  CardsContainer,
  TextContainer,
  CardTitle,
  CardDescription,
  ArrowIcon,
  Arrowontainer,
  FirstAmoeba,
  SecondAmoeba,
  Smile,
  TopContainer,
  ThirdAmoeba,
  ImageContainer,
} from './style'

const Understand = ({ theme, isTablet }) => {
  const list = [
    {
      id: 1,
      title: 'O que é Moebius',
      description:
        'Considerada uma síndrome rara, uma em cada 50 mil crianças nascem portadoras, a Síndrome de Moebius não deve ser encarada como uma sentença.',
      color: theme.colors.tertiaryMedium,
      path: '/entendendo-moebius',
    },
    {
      id: 2,
      title: 'Tratamentos disponíveis',
      description:
        'Conheça as possibilidades de tratamento que podem colaborar na qualidade de vida de um Moebita.',
      color: theme.colors.secondaryDarkest,
      path: '/tratamentos-disponiveis',
    },
    {
      id: 3,
      title: 'Nossas histórias',
      description:
        'Os Moebitas são pessoas incríveis e têm histórias que vão encantar você.',
      color: theme.colors.quaternaryDarkest,
      path: '/nossas-historias',
    },
    {
      id: 4,
      title: 'Artigos e Pesquisas',
      description:
        'Informações produzidas por médicos e cientistas que estudam a síndrome.',
      color: theme.colors.primaryMedium,
      path: '/artigos-e-pesquisas',
    },
    {
      id: 5,
      title: 'Instituições e Clínicas',
      description:
        'Temos uma lista de profissionais que conhecem a síndrome e irão ajudar você com as melhores informações.',
      color: theme.colors.tertiaryMedium,
      path: '/instituicoes-e-clinicas',
    },
  ]

  const renderCard = (item) => (
    <Card background={item.color} key={item.title} as={Link} to={item.path}>
      <ImageContainer>
        {item.id === 1 && (
          <StaticImage
            src="../../../assets/images/home-small-picture1.png"
            alt=""
            placeholder="blurred"
            className="picture"
          />
        )}
        {item.id === 2 && (
          <StaticImage
            src="../../../assets/images/home-small-picture4.png"
            alt=""
            placeholder="blurred"
            className="picture"
          />
        )}
        {item.id === 3 && (
          <StaticImage
            src="../../../assets/images/home-small-picture3.png"
            alt=""
            placeholder="blurred"
            className="picture"
          />
        )}
        {item.id === 4 && (
          <StaticImage
            src="../../../assets/images/home-small-picture2.png"
            alt=""
            placeholder="blurred"
            className="picture"
          />
        )}
        {item.id === 5 && (
          <StaticImage
            src="../../../assets/images/home-small-picture5.png"
            alt=""
            placeholder="blurred"
            className="picture"
          />
        )}
      </ImageContainer>
      <TextContainer>
        <CardTitle>{item.title} </CardTitle>
        <CardDescription>{item.description} </CardDescription>
      </TextContainer>
      <Arrowontainer>
        <ArrowIcon />
      </Arrowontainer>
    </Card>
  )

  return (
    <Container>
      <FirstAmoeba />
      <SecondAmoeba />
      <ThirdAmoeba />
      <TopContainer>
        <Smile />
        <Title>Entendendo melhor</Title>
        <Description>
          Sabemos que muitas dúvidas e medo podem surgir após um diagnóstico,
          por isso queremos ajudar você a entender melhor a síndrome.
        </Description>
      </TopContainer>
      <CardsContainer>
        {isTablet && (
          <Carousel
            spaceBetweenCards={20}
            primaryButton
            nextButtonClass="home-understand"
            prevButtonClass="home-understand"
          >
            {list.map((item) => (
              <SwiperSlide key={item.title}>{renderCard(item)}</SwiperSlide>
            ))}
          </Carousel>
        )}
        {!isTablet && list.map((item) => renderCard(item))}
      </CardsContainer>
    </Container>
  )
}

export default withTheme(Understand)
