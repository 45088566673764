import styled, { keyframes } from 'styled-components'
import Modal from 'styled-react-modal'
import Check from 'assets/vectors/alert-positive-icon.svg'
import ErrorAlert from 'assets/vectors/error-icon.svg'

const show = keyframes`
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  `

export const CustomModal = Modal.styled`
  height: 1493px;
  width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 29px 40px;
  margin-top: 40px;
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: 1400px) {
      width: 940px;
    }
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 90%;
      padding: 18px;
      padding-bottom: 42px;
      height: fit-content;
    }
  `}
`

export const Overlay = styled.div`
  display: flex;
  position: absolute;
  width: 100vw;
  inset: 0px;
  top: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  animation: ${show} 0.4s ease forwards;
`

export const CloseButton = styled.button`
  position: relative;
  align-self: flex-end;
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
`

export const Close = styled.div`
  position: absolute;
  left: -3px;
  top: 0;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 24px;
    width: 3px;
    background-color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

export const Body = styled.div`
  display: flex;
  width: 725px;
  flex-direction: column;
  z-index: 10;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 500px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      align-items: center;
    }
  `}
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.tertiaryMedium};
  margin-bottom: 56px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading4};
      margin-bottom: 17px;
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => theme.fonts.largeText}
  max-width: 662px;
  color: ${({ theme }) => theme.colors.neutralDarkest};
  margin-bottom: 60px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextSemiBold};
      text-align: center;
      max-width: 238px;
    }
  `}
`

export const AcceptInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 56px;
  cursor: pointer;

  span {
    ${({ theme }) => theme.fonts.mediumText}
    color: ${({ theme }) => theme.colors.neutralMedium};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 288px;
      margin-bottom: 40px;

      span {
        ${theme.fonts.smallTextSemiBold};
        max-width: 187px;
      }
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      width: 234px;
    }
  `}
`

export const Checkbox = styled.input`
  position: relative;
  cursor: pointer;
  margin-right: 10px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -3px;
    left: -5px;
    border: 2px solid ${({ theme }) => theme.colors.neutralMedium};
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.backgroundLight};
  }

  &:checked:before {
    border-color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  &:checked:after {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    top: 1px;
    left: -1px;
    background-color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 33px;
      &:before {
        width: 33px;
        height: 33px;
        top: -8px;
        border: 3px solid ${theme.colors.neutralMedium};
      }

      &:checked:after {
        width: 18px;
        height: 18px;
        top: 0px;
        left: 2px;
      }
    }
  `}
`
export const Disclaimer = styled.div`
  transform: translateY(-35px);
  span {
    ${({ theme }) => theme.fonts.smallTextSemiBold};
    color: ${({ theme }) => theme.colors.neutralMedium};
  }
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      transform: translateY(-19px);
  `}
`

export const CheckIcon = styled(Check)`
  width: 48px;
  height: 48px;
  margin-right: 24px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 40px;
      height: 40px;
  `}
`

export const ErrorIcon = styled(ErrorAlert)`
  margin-right: 24px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 40px;
      height: 40px;
  `}
`
