import React from 'react'

import {
  Container,
  Card,
  TextContainer,
  ItemsContainer,
  Title,
  Description,
  Row,
  PartnerTitle,
  FirstAmoeba,
  SecondAmoeba,
  ThirdAmoeba,
} from './style'

const Partners = ({ data }) => {
  const findAmoeba = (index) => {
    if (index % 3 === 0) return <ThirdAmoeba />
    if (index % 2 === 0) return <FirstAmoeba />
    return <SecondAmoeba />
  }

  return (
    <Container>
      <Card>
        <TextContainer>
          <Title>Nossos parceiros</Title>
          <Description>
            Temos uma lista de profissionais que têm conhecimento sobre a
            Síndrome de Moebius e poderão auxiliar no desenvolvimento dos
            Moebitas.
          </Description>
        </TextContainer>
        <ItemsContainer>
          {data.map((item, index) => (
            <Row
              href={item.url}
              target="_blank"
              id={item.id}
              key={item.id}
              rel="noreferrer"
            >
              {findAmoeba(index)}
              <PartnerTitle>
                {item.name}
                {item.role && <span> ({item.role})</span>}
              </PartnerTitle>
            </Row>
          ))}
        </ItemsContainer>
      </Card>
    </Container>
  )
}
export default Partners
