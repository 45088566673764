import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  background: ${({ theme }) => theme.colors.backgroundLight};

  p {
    ${({ theme }) => theme.fonts.mediumTextBold};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding-top: 24px;
      padding-bottom: 24px;
      p {
        ${theme.fonts.smallTextBlack};
        padding-right: 15px;
      }
    }
  `}
`
