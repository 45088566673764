import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;

  span {
    visibility: ${({ filled }) => (filled ? 'visible' : 'hidden')};
    position: absolute;
    ${({ theme }) => theme.fonts.smallTextSemiBold};
    padding: 0 3px 0;
    transform: translate(10px, -7px);
    background: ${({ theme }) => theme.colors.backgroundLight};

    color: ${({ theme, filled, typing }) =>
      filled && typing && theme.colors.tertiaryMedium};
    color: ${({ theme, filled, typing }) =>
      filled && !typing && theme.colors.primaryDarkest};

    ${({ theme, hasError }) =>
      hasError &&
      `
      visibility: visible;
      color: ${theme.colors.error};
    `}
    color: ${({ theme, typing }) => typing && theme.colors.tertiaryMedium};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-bottom: 24px;
    }
  `}
`

const inputStyle = css`
  width: 724px;
  padding-top: 13px;
  padding-bottom: 19px;
  padding-left: 15px;

  border: 1px solid ${({ theme }) => theme.colors.neutralMedium};
  border-radius: 4px;

  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.neutralMedium};

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.tertiaryMedium};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  &:not(focus) {
    border: 2px solid
      ${({ theme, filled }) =>
        filled ? theme.colors.primaryDarkest : theme.colors.tertiaryMedium};
    color: ${({ theme }) => theme.colors.neutralDarkest};

    ${({ theme, filled, hasError }) =>
      filled &&
      !hasError &&
      `
      border: 2px solid ${theme.colors.primaryDarkest}
    `}
    border: 1px solid ${({ theme }) => theme.colors.neutralMedium};

    ${({ theme, hasError }) =>
      hasError &&
      `
      border-color: ${theme.colors.error};
    `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextSemiBold};
      width: 300px;
      padding: 13px 18px 16px 16px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      width: 251px;
    }
  `}
`

export const InputText = styled.input`
  ${inputStyle}
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    ${({ theme }) => theme.fonts.mediumText};
    color: ${({ theme }) => theme.colors.neutralDarkest};

    border: 2px solid ${({ theme }) => theme.colors.primaryDarkest};
    box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.backgroundLight}
      inset;
  }
`

export const TextAreaField = styled.textarea`
  ${inputStyle}
  height: 239px;
  resize: none;
`

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.fonts.smallTextSemiBold};
  color: ${({ theme }) => theme.colors.error};
  padding-top: 3px;
  padding-left: 16px;
`
