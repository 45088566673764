import styled from 'styled-components'
import Arrow from 'assets/vectors/arrow-head.svg'
import Button from 'components/Button'

export const ArrowIcon = styled(Arrow)`
  margin-left: 10px;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 52px 0 52px 125px;
  background: ${({ theme }) => theme.colors.tertiaryMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      justify-content: center;
      padding-left: 18px;
    }
  `}
`

export const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.backgroundLight};
  margin-bottom: 25px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3};
      text-align: center;
    }
  `}
`

export const SectionDescription = styled.p`
  ${({ theme }) => theme.fonts.largeText};
  color: ${({ theme }) => theme.colors.backgroundLight};
  max-width: 572px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumText};
      text-align: center;
    }
  `}
`

export const CardsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 60px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-top: 47px;
      margin-bottom: 142px;
    }
  `}

  .swiper-slide {
    width: unset;
  }

  .swiper-button-next-home-news {
    position: absolute;
    top: -93px;
    right: 49px;

    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.biggestMobile}) {
        top: unset;
        bottom: -98px;
        right: 30%;
      }
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        right: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        right: 24%;
      }
    `}
  }

  .swiper-button-prev-home-news {
    position: absolute;
    top: -93px;
    right: 136px;

    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.biggestMobile}) {
        top: unset;
        bottom: -98px;
        right: unset;
        left: 30%;
      }
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        left: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        left: 24%;
      }
    `}
  }
`

export const Card = styled.div`
  display: flex;
  width: 579px;
  height: 359px;
  flex-direction: column;
  padding: 49px 10px 33px 33px;
  border-radius: 8px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.colors.backgroundLight};
  box-sizing: border-box;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 500px;
      height: 400px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 251px;
      height: 310px;
      padding: 33px 16px 32px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      width: 242px;
    }
  `}
`

export const CardTop = styled.div`
  display: flex;

  ${({ theme }) => theme.fonts.subtitle}
  color: ${({ theme }) => theme.colors.neutralMedium};
`

export const Type = styled.span`
  margin-right: 4px;
  text-transform: uppercase;
`

export const Year = styled.span`
  margin-left: 4px;
  text-transform: uppercase;
`

export const Title = styled.h3`
  max-width: 544px;
  margin-top: 16px;

  ${({ theme }) => theme.fonts.heading4}
  color: ${({ theme }) => theme.colors.tertiaryMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 513px;
      ${theme.fonts.mediumTextBlack}
    }
  `}
`

export const Description = styled.p`
  max-width: 431px;
  margin-top: 24px;
  ${({ theme }) => theme.fonts.mediumText}
  color: ${({ theme }) => theme.colors.neutralMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium}
    }
  `}
`

export const LinkContainer = styled.div`
  display: flex;
  height: 100%;
  width: 130px;
  align-items: flex-end;
  margin-top: 24px;
  cursor: pointer;
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.primaryDarkest};
`

export const ButtonContainer = styled.div`
  width: fit-content;
  align-self: center;
  display: flex;
  justify-content: center;
  padding-top: 85px;
  padding-bottom: 80px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  `}
`

export const ButtonRedirect = styled(Button)`
  background-color: ${({ theme }) => theme.colors.quaternaryDarkest};

  &:hover {
    background-color: ${({ theme }) => theme.colors.quaternaryMedium};
  }
`
