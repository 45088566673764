import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { Link } from 'gatsby'

import Carousel from 'components/Carousel'

import { formatPastEventsDate } from 'utils/date'

import {
  Container,
  SectionTitle,
  SectionDescription,
  ArrowIcon,
  CardsContainer,
  Card,
  CardTop,
  Type,
  Year,
  Title,
  Description,
  LinkContainer,
  ButtonContainer,
  ButtonRedirect,
} from './style'

const News = ({
  isMobile,
  data,
  setIsEventModalOpen,
  setEventModalContent,
  scrollToTop,
}) => {
  const orderedData = data.sort(
    (a, b) => new Date(a.rawData).getTime() - new Date(b.rawData).getTime(),
  )

  const truncate = (text, size) =>
    text?.length > size ? `${text.substring(0, size)}[...]` : text

  return (
    <Container>
      <SectionTitle>Eventos e Notícias</SectionTitle>
      <SectionDescription>
        Queremos compartilhar com você nossos eventos e informações mais
        recentes sobre a Síndrome de Moebius.
      </SectionDescription>
      <CardsContainer>
        <Carousel
          spaceBetweenCards={isMobile ? 20 : 50}
          primaryButton
          nextButtonClass="home-news"
          prevButtonClass="home-news"
        >
          {orderedData.map((item) => (
            <SwiperSlide key={item.id}>
              <Card>
                <CardTop>
                  <Type>{item?.newsTitle ? 'Notícias' : 'Evento'}</Type>|
                  <Year>{formatPastEventsDate(item.rawDate)}</Year>
                </CardTop>
                <Title>
                  {isMobile
                    ? truncate(item?.title || item?.newsTitle, 40)
                    : truncate(item?.title || item?.newsTitle, 70)}
                </Title>
                <Description>
                  {truncate(
                    item.briefDescription?.description || item.briefDescription,
                    100,
                  )}
                </Description>
                {item?.url ? (
                  <LinkContainer as={Link} to={item.url}>
                    Saiba Mais
                    <ArrowIcon />
                  </LinkContainer>
                ) : (
                  <LinkContainer
                    onClick={() => {
                      setIsEventModalOpen(true)
                      setEventModalContent(item)
                      scrollToTop()
                    }}
                  >
                    Saiba Mais
                    <ArrowIcon />
                  </LinkContainer>
                )}
              </Card>
            </SwiperSlide>
          ))}
        </Carousel>
      </CardsContainer>
      <ButtonContainer as={Link} to="/eventos-e-noticias">
        <ButtonRedirect>Saiba Mais</ButtonRedirect>
      </ButtonContainer>
    </Container>
  )
}

export default News
