import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container,
  TextContainer,
  Title,
  Subtitle,
  ImageContainer,
  BottomAmoeba,
  Amoeba,
  AmoebaMobile,
} from './style'

const Header = () => (
  <Container>
    <ImageContainer>
      <Amoeba />
      <AmoebaMobile />
      <StaticImage
        src="../../../assets/images/home-header-picture.png"
        alt=""
        placeholder="blurred"
        className="picture"
      />
    </ImageContainer>
    <TextContainer>
      <Title>Sorrisos vêm do coração</Title>
      <Subtitle>
        AMoB é um espaço de acolhimento e conexão para portadores da Síndrome de
        Moebius, pais, médicos e parceiros
      </Subtitle>
    </TextContainer>
    <BottomAmoeba />
  </Container>
)

export default Header
