import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/home-header-vector1.svg'
import Amoeba1Mobile from 'assets/vectors/home-header-vector1-mobile.svg'
import Amoeba2 from 'assets/vectors/home-header-vector2.svg'

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 650px;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primaryMedium};

  ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet}) {
      height: 850px;
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.smallTablet}) {
      height: 765px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: fit-content;
      flex-direction: column;
    }
  `}
`

export const TextContainer = styled.div`
  margin-top: 100px;
  transform: translateX(-60px);
  z-index: 4;
  ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: 1350px) {
      margin-top: 0px;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet}) {
      margin-bottom: 40px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 28px 37px;
      margin-top: 8px;
      transform: none;
    }
  `}
`

export const Title = styled.h1`
  max-width: 600px;
  margin-bottom: 30px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3}
      max-width: 300px;
      margin-bottom: 15px;
    }
  `}
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.backgroundLight};
  max-width: 478px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium}
      max-width: 300px;
    }
  `}
`

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 700px;
  display: flex;
  justify-content: center;

  .picture {
    position: absolute !important;
    z-index: 2;

    ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: 1350px) {
      width: 450px;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet}) {
      width: 400px;
      top: -10px;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.smallTablet}) {
      top: -55px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 320px;   
      top: 20px;
    }
  `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100vw;
      height: 357px;
    }
  `}
`

export const BottomAmoeba = styled(Amoeba2)`
  position: absolute;
  bottom: -160px;
  right: -1px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 153px;
      width: 152px;
      bottom: -107px;
      right: -44px;
      z-index: 1;
      transform: rotate(261deg);
    }
  `}
`

export const Amoeba = styled(Amoeba1)`
  position: absolute;
  bottom: 35px;
  left: -70px;

  ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: 1350px) {
      width: 600px;
      height: 450px;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: 1440px) {
      bottom: 40px;
      left: -50px;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet}) {
      left: unset;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.smallTablet}) {
      right: 76px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const AmoebaMobile = styled(Amoeba1Mobile)`
  position: absolute;
  display: none;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: block;
      top: 43px;
      right: -31px;
    }
    @media (max-width: ${theme.breakPoints.mediumMobile}) {
      right: -49px;
    }
    @media (max-width: ${theme.breakPoints.smallMobile}) {
      top: 21px;
      right: -65px;
      }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      top: 32px;
      right: -91px;
    }
  `}
`
