import React from 'react'

import { Container } from './style'

const Toast = ({ text, icon }) => (
  <Container>
    {icon}
    {text}
  </Container>
)

export default Toast
