import styled from 'styled-components'
import Arrow from 'assets/vectors/arrow-card.svg'
import Amoeba1 from 'assets/vectors/home-understand-vector1.svg'
import Amoeba2 from 'assets/vectors/home-understand-vector2.svg'
import Amoeba3 from 'assets/vectors/home-understand-vector3.svg'
import SmileOrange from 'assets/vectors/smile-orange.svg'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 150px;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 88px 0px 165px;
    }
  `}
`

export const TopContainer = styled.div`
  position: relative;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 0px 25px;
    }
  `}
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primaryMedium};
  margin-bottom: 30px;
  z-index: 2;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      position: relative;
      ${theme.fonts.heading3};
      text-align: center;
      z-index: 2;
    }
  `}
`

export const Description = styled.p`
  position: relative;
  max-width: 663px;
  ${({ theme }) => theme.fonts.largeText};
  text-align: center;
  color: ${({ theme }) => theme.colors.neutralDarkest};
  z-index: 2;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      font-family: 'Montserrat-Medium';
      font-size: 18px;
      line-height: 22px;
    }
  `}
`

export const Card = styled.div`
  display: flex;
  width: 251px;
  height: 455px;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background: ${({ background }) => background};
  z-index: 2;

  :not(:nth-last-child(1)) {
    margin-right: 23px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 401px;
    }
  `}
`

export const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1350px;
  justify-content: center;
  margin-top: 105px;

  .swiper-slide {
    width: unset;
  }

  .swiper-button-next-home-understand {
    position: absolute;
    bottom: 70px;
    right: 30%;
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        right: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        right: 24%;
      }
    `}
  }

  .swiper-button-prev-home-understand {
    position: absolute;
    bottom: 70px;
    left: 30%;
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        left: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        left: 24%;
      }
    `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: unset;
      width: 100%;
      margin-top: 40px;
    }
  `}
`

export const CardTitle = styled.p`
  ${({ theme }) => theme.fonts.heading4};
  max-width: 150px;
  margin-top: 21px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.backgroundLight};
`

export const CardDescription = styled.p`
  max-width: 216px;
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium};
    }
  `}
`

export const TextContainer = styled.div``
export const ImageContainer = styled.div`
  .picture {
    height: 167px;
    img {
      height: 167px;
    }
  }
`

export const Arrowontainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
`

export const ArrowIcon = styled(Arrow)`
  path {
    fill: ${({ theme }) => theme.colors.backgroundLight};
  }
`

export const Smile = styled(SmileOrange)`
  position: absolute;
  right: -253px;
  top: -37px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 224px;
      height: 213px;
      right: 0px;
      top: 100px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 224px;
      height: 213px;
      right: unset;
      top: -83px;
      left: -49px;
      transform: rotate(-43deg);
    }
  `}
`

export const FirstAmoeba = styled(Amoeba1)`
  position: absolute;
  left: 0;
  top: 150px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const ThirdAmoeba = styled(Amoeba3)`
  display: none;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: flex;
      position: absolute;
      left: 0;
      bottom: 70px;
    }
  `}
`

export const SecondAmoeba = styled(Amoeba2)`
  position: absolute;
  right: 0;
  bottom: 0;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`
