import React from 'react'
import { withTheme } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { StaticImage } from 'gatsby-plugin-image'

import Button from 'components/Button'

import { Container, Amoeba, Title, Description } from './style'

const ContactUs = ({ theme, onClickButton }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.biggestMobile})`,
  })

  return (
    <Container>
      <Amoeba />
      {typeof window !== `undefined` && isMobile && (
        <StaticImage
          src="../../../assets/images/home-contact-us-hands-mobile.png"
          alt=""
          placeholder="blurred"
          className="picture"
        />
      )}
      {typeof window !== `undefined` && !isMobile && (
        <StaticImage
          src="../../../assets/images/home-contact-us-hands.png"
          alt=""
          placeholder="blurred"
          className="picture"
        />
      )}
      <Title>Fale com a gente</Title>
      <Description>
        {isMobile
          ? 'Nosso objetivo é ser um elo entre Moebitas, pais e parceiros de todo o Brasil.'
          : 'Que bom que nos encontramos! Na AMoB estamos sempre abertos para receber pessoas que tenham dúvidas ou que estejam interessadas em contribuir de alguma forma com os Moebitas. Seja bem-vindo! '}
      </Description>
      <Button small={isMobile} onClick={onClickButton}>
        Faça parte da AMoB
      </Button>
    </Container>
  )
}
export default withTheme(ContactUs)
