import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  isPastDate,
  formatUpcommingEventsDate,
  formatPastEventsDate,
} from 'utils/date'

import useNewsAndEvents from 'graphql/useNewsAndEvents'
import useHomePartners from 'graphql/useHomePartners'

import theme from 'styles/theme'

import Seo from 'components/Seo'
import Layout from 'components/Layout'
import Header from 'components/Home/Header'
import AboutUs from 'components/Home/AboutUs'
import Understand from 'components/Home/Understand'
import News from 'components/Home/News'
import ContactUs from 'components/Home/ContactUs'
import Partners from 'components/Home/Partners'
import ModalForm from 'components/Home/ModalForm'
import Toast from 'components/Toast'
import EventModal from 'components/News/EventModal'

const Wrapper = styled.div`
  .Toastify__toast-container {
    width: unset;
  }

  .Toastify__toast-body {
    padding: 0px;
    width: 100%;
  }

  .Toastify__toast {
    width: 728px;
    height: 109px;

    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0px;

    &--error {
      border-top: 12px solid ${theme.colors.error};
      background: ${theme.colors.backgroundLight};
    }
    &--success {
      border-top: 12px solid ${theme.colors.tertiaryDarkest};
      background: ${theme.colors.backgroundLight};
    }

    @media only screen and (max-width: 500px) {
      width: 100%;
      height: fit-content;
    }
  }

  .Toastify__close-button {
    opacity: 1;
    margin: auto;
    padding-right: 40px;
    color: ${theme.colors.neutralDarkest};

    svg {
      width: 24px;
      height: 24px;
    }
  }
`

const IndexPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hasSendFormError, setHasSendFormError] = useState(false)

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.biggestMobile})`,
  })

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.tablet})`,
  })

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const showToaster = ({ text, icon }) =>
    toast(<Toast text={text} icon={icon} />)

  const newsAndEventsData = useNewsAndEvents()
  const events = newsAndEventsData.events.nodes.slice(0, 3)
  const newsData = newsAndEventsData.news.nodes.slice(0, 3)

  const eventsData = []
  events.forEach((eventItem) => {
    if (eventItem.id !== '5c390358-af23-57a2-843e-78e521799d77')
      if (isPastDate(eventItem.rawDate)) {
        eventsData.push({
          ...eventItem,
          formattedDate: formatPastEventsDate(eventItem.rawDate),
        })
      } else {
        eventsData.push({
          ...eventItem,
          formattedDate: formatUpcommingEventsDate(eventItem.rawDate),
        })
      }
  })

  const [isEventModalOpen, setIsEventModalOpen] = useState(false)
  const [eventModalContent, setEventModalContent] = useState({})

  const partnersData = useHomePartners().partners.nodes

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Layout css={{ backgroundColor: theme.colors.primaryMedium }} isPurple>
          <Seo
            title="Home"
            description="AMoB é um espaço de acolhimento e conexão para portadores da Síndrome de Moebius, pais, médicos e parceiros"
          />
          <Header />
          <AboutUs isMobile={isMobile} />
          <Understand isTablet={isTablet} />
          <News
            isMobile={isMobile}
            data={[...eventsData, ...newsData]}
            setIsEventModalOpen={setIsEventModalOpen}
            setEventModalContent={setEventModalContent}
            scrollToTop={scrollToTop}
          />
          <EventModal
            isPreviewHome
            isModalOpen={isEventModalOpen}
            onRequestClose={() => setIsEventModalOpen(false)}
            data={eventModalContent}
          />
          <ContactUs
            onClickButton={() => {
              setIsModalOpen(true)
              scrollToTop()
              setHasSendFormError(false)
            }}
          />
          <ModalForm
            isModalOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            showToaster={showToaster}
            setHasSendFormError={setHasSendFormError}
          />
          <ToastContainer
            position="top-center"
            hideProgressBar
            toastClassName={
              hasSendFormError
                ? 'Toastify__toast--error'
                : 'Toastify__toast--success'
            }
          />
          <Partners data={partnersData} />
        </Layout>
      </Wrapper>
    </ThemeProvider>
  )
}

export default IndexPage
