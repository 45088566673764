import styled from 'styled-components'

import Arrow from 'assets/vectors/arrow-right.svg'
import Amoeba1 from 'assets/vectors/home-about-vector1.svg'
import Amoeba2 from 'assets/vectors/home-about-vector2.svg'
import FenceSvg from 'assets/vectors/home-about-fence.svg'

export const ArrowIcon = styled(Arrow)`
  path {
    fill: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 15px;
    }
  `}
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      position: relative;
      padding: 0 25px;
    }
  `}
`

export const Fence = styled(FenceSvg)`
  position: absolute;
  top: -200px;
  left: -100px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      top: 0px;
      left: -39px;
      width: 239px;
      height: 134px;
    }
  `}
`

export const Card = styled.div`
  position: relative;
  min-width: 1200px;
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  margin-top: 116px;
  background: ${({ theme }) => theme.colors.quaternaryMedium};

  ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column-reverse;
      min-width: 100%;
      min-height: 990px;
      align-items: center;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.smallTablet}) {
      min-height: 1095px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      flex-direction: column-reverse;
      min-width: unset;
      width: 100%;
      min-height: 521px;
      padding: 24px 24px 52px;
    }
  `}
`

export const TextContainer = styled.div`
  max-width: 663px;
  display: flex;
  flex-direction: column;
  padding: 74px 0px 74px 83px;

  ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet}) {
      align-items: center;
      padding: 74px 38px
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      min-width: unset;
      width: 100%;
      align-items: center;
      padding: 0px;
    }
  `}
`

export const Title = styled.h2`
  ${({ theme }) => theme.fonts.heading1};
  color: ${({ theme }) => theme.colors.backgroundLight};
  margin-bottom: 48px;

  ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet}) {
      text-align: center;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3};
      margin-bottom: 20px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      text-align: center;
    }
  `}
`

export const Subtitle = styled.p`
  max-width: 500px;
  ${({ theme }) => theme.fonts.largeText};
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      text-align: center;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 261px;
      ${theme.fonts.smallTextMedium};
    }
  `}
`

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`

export const LinkText = styled.p`
  display: flex;
  ${({ theme }) => theme.fonts.largeBold};
  color: ${({ theme }) => theme.colors.backgroundLight};
  margin-right: 25px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextBlack};
      margin-right: 10px;
    }
  `}
`

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 500px;
  align-items: flex-end;
  justify-content: center;

  .picture {
    position: absolute;
    z-index: 3;

    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 200px;
      height: 200px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      width: 170px;
      height: 170px;
    }
  `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const FirstAmoeba = styled(Amoeba1)`
  position: absolute;
  right: 69px;
  top: 32px;
  z-index: 2;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 113px;
      height: 99px;
      right: 64px;
      top: 2px;
    }
    @media (max-width: ${theme.breakPoints.mediumMobile}) {
      right: 25px;
    }
    @media (max-width: ${theme.breakPoints.smallMobile}) {
      right: 25px;
      top: 5px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      right: 24px;
      top: 8px;
    }
  `}
`

export const SecondAmoeba = styled(Amoeba2)`
  position: absolute;
  top: 32px;
  left: 0;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 210px;
      height: 225px;
      top: 2px;
    }
    @media (max-width: ${theme.breakPoints.smallMobile}) {
      left: -20px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      width: 180px;
      height: 200px;
      top: -11px;
      left: -21px;
    }
  `}
`
