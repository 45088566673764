import styled from 'styled-components'
import Vector1 from 'assets/vectors/home-partner1.svg'
import Vector2 from 'assets/vectors/home-partner2.svg'
import Vector3 from 'assets/vectors/home-partner3.svg'

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 810px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      height: unset;
    }
  `}
`

export const Card = styled.div`
  display: flex;
  padding: 87px 50px 152px 114px;
  align-items: center;
  margin-top: 114px;
  margin-bottom: 114px;
  background: ${({ theme }) => theme.colors.quaternaryLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 46px 30px 49px 40px;
    }
  `}
`

export const TextContainer = styled.div`
  display: flex;
  max-width: 426px;
  flex-direction: column;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
    }
  `}
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primaryMedium};
  margin-bottom: 41px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3};
      max-width: 298px;
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => theme.fonts.largeText};
  color: ${({ theme }) => theme.colors.neutralDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumText};
      max-width: 298px;
    }
  `}
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 66px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      margin-top: 40px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-left: 0;
      margin-top: 18px;
    }
  `}
`

export const FirstAmoeba = styled(Vector1)``

export const SecondAmoeba = styled(Vector2)`
  path {
    fill: ${({ theme }) => theme.colors.tertiaryMedium};
  }
`

export const ThirdAmoeba = styled(Vector3)``

export const Row = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.quaternaryDarkest};
      }
    }
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-bottom: 30px;
  `}
`

export const PartnerTitle = styled.p`
  ${({ theme }) => theme.fonts.heading4};
  color: ${({ theme }) => theme.colors.neutralDarkest};
  max-width: 466px;
  transform: translateX(-27px);

  span {
    ${({ theme }) => theme.fonts.largeText};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumTextBlack};
      width: 100%;
      span {
        ${theme.fonts.mediumText};
      }
    }
  `}
`
