import { graphql, useStaticQuery } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query {
      partners: allContentfulParceirosMostradosNaHome {
        nodes {
          name: nomeDoParceiro
          role: especialidadeDoParceiro
          url: linkDoParceiro
          id
        }
      }
    }
  `)
