import React, { useState, useEffect } from 'react'
import { ModalProvider } from 'styled-react-modal'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'

import PrimaryInput from 'components/PrimaryInput'
import Button from 'components/Button'

import emailService from 'services/sendEmail'

import {
  CustomModal,
  Overlay,
  CloseButton,
  Close,
  Body,
  Title,
  Description,
  AcceptInfoContainer,
  Checkbox,
  Disclaimer,
  CheckIcon,
  ErrorIcon,
} from './style'

const ModalForm = ({
  isModalOpen,
  onRequestClose,
  showToaster,
  setHasSendFormError,
}) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [country, setCountry] = useState('')
  const [phone, setPhone] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [receiveInfo, setReceiveInfo] = useState(false)
  const [hasSubmited, setHasSubmited] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setHasSubmited(false)
    setName('')
    setEmail('')
    setCountry('')
    setPhone('')
    setSubject('')
    setMessage('')
    setReceiveInfo('')
  }, [isModalOpen])

  const isValidForm = () => {
    let isValid = false
    if (!isEmpty(email) && isEmail(email)) {
      isValid = [name, country, phone, subject, message].every(
        (field) => !isEmpty(field),
      )
    }
    return isValid
  }

  const handleSubmit = async () => {
    setHasSubmited(true)
    if (isValidForm()) {
      setIsLoading(true)
      const response = await emailService.sendEmail({
        email,
        name,
        country,
        phone,
        subject,
        message,
        receiveInfo,
      })

      if (response.status !== 200) {
        setHasSendFormError(true)
        setTimeout(() => {
          setIsLoading(false)
          onRequestClose()
          showToaster({
            text: (
              <p>
                Aconteceu um erro no envio das suas informações.
                <br />
                Tente enviar novamente em alguns instantes.
              </p>
            ),
            icon: <ErrorIcon />,
          })
        }, 1000)
      } else {
        setHasSendFormError(false)
        setTimeout(() => {
          setIsLoading(false)
          onRequestClose()
          showToaster({
            text: (
              <p>
                Suas informações foram enviadas! <br />
                Em breve entraremos em contato com você.
              </p>
            ),
            icon: <CheckIcon />,
          })
        }, 1000)
      }
    }
  }

  return (
    <ModalProvider backgroundComponent={Overlay}>
      <CustomModal
        isOpen={isModalOpen}
        onBackgroundClick={onRequestClose}
        onEscapeKeydown={onRequestClose}
      >
        <CloseButton onClick={onRequestClose}>
          <Close />
        </CloseButton>
        <Body>
          <Title>Fale com a gente</Title>
          <Description>
            Que bom que nos encontramos! Na AMoB estamos sempre abertos para
            receber pessoas que tenham dúvidas ou que estejam interessadas em
            contribuir de alguma forma com os Moebitas. Seja bem-vindo!
          </Description>
          <form onSubmit={() => handleSubmit()}>
            <PrimaryInput
              placeholder="Nome*"
              onChange={(e) => setName(e.target.value)}
              filled={!isEmpty(name)}
              hasError={hasSubmited && isEmpty(name)}
              errorMessage="Campo obrigatório"
            />
            <PrimaryInput
              placeholder="E-mail*"
              onChange={(e) => setEmail(e.target.value)}
              filled={!isEmpty(email)}
              hasError={hasSubmited && (isEmpty(email) || !isEmail(email))}
              errorMessage="Campo obrigatório"
            />
            <PrimaryInput
              placeholder="País*"
              onChange={(e) => setCountry(e.target.value)}
              filled={!isEmpty(country)}
              hasError={hasSubmited && isEmpty(country)}
              errorMessage="Campo obrigatório"
            />
            <PrimaryInput
              type="tel"
              placeholder="Telefone*"
              onChange={(e) => setPhone(e.target.value)}
              filled={!isEmpty(phone)}
              hasError={hasSubmited && isEmpty(phone)}
              errorMessage="Campo obrigatório"
            />
            <PrimaryInput
              placeholder="Assunto*"
              onChange={(e) => setSubject(e.target.value)}
              filled={!isEmpty(subject)}
              hasError={hasSubmited && isEmpty(subject)}
              errorMessage="Campo obrigatório"
            />
            <PrimaryInput
              textArea
              placeholder="Mensagem*"
              onChange={(e) => setMessage(e.target.value)}
              filled={!isEmpty(message)}
              hasError={hasSubmited && isEmpty(message)}
              errorMessage="Campo obrigatório"
            />
          </form>
          <Disclaimer>
            <span>*Campos obrigatórios</span>
          </Disclaimer>
          <AcceptInfoContainer onClick={() => setReceiveInfo(!receiveInfo)}>
            <Checkbox type="checkbox" checked={receiveInfo} />
            <span>Aceito receber informações sobre a AMoB</span>
          </AcceptInfoContainer>
          <Button
            type="submit"
            onClick={() => handleSubmit()}
            showLoader={isLoading}
          >
            Enviar
          </Button>
        </Body>
      </CustomModal>
    </ModalProvider>
  )
}
export default ModalForm
