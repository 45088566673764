import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container,
  Fence,
  Card,
  TextContainer,
  Title,
  Subtitle,
  ImageContainer,
  FirstAmoeba,
  SecondAmoeba,
  LinkContainer,
  LinkText,
  ArrowIcon,
} from './style'

const AboutUs = ({ isMobile }) => (
  <Container>
    {isMobile && <Fence />}
    <Card>
      {!isMobile && <Fence />}
      <TextContainer>
        <Title>Quem somos</Title>
        <Subtitle>
          Conheça a nossa história, os objetivos que temos como base e a equipe
          que mantém todo o funcionamento da associação
        </Subtitle>
        <LinkContainer as={Link} to="quem-somos">
          <LinkText>Venha nos conhecer melhor</LinkText>
          <ArrowIcon />
        </LinkContainer>
      </TextContainer>
      <ImageContainer>
        <FirstAmoeba />
        <SecondAmoeba />
        <StaticImage
          src="../../../assets/images/home-about-picture.png"
          alt=""
          placeholder="blurred"
          className="picture"
        />
      </ImageContainer>
    </Card>
  </Container>
)

export default AboutUs
