import React, { useState } from 'react'

import { Container, InputText, TextAreaField, ErrorMessage } from './style'

const PrimaryInput = ({
  placeholder,
  onChange,
  filled,
  textArea,
  type = 'text',
  hasError,
  errorMessage,
}) => {
  const [typing, setTyping] = useState(false)

  return (
    <Container filled={filled} typing={typing} hasError={hasError}>
      <span>{placeholder}</span>
      {textArea ? (
        <TextAreaField
          hasError={hasError}
          placeholder={placeholder}
          onChange={onChange}
          filled={filled}
          onBlur={() => setTyping(false)}
          onClick={() => setTyping(true)}
        />
      ) : (
        <InputText
          hasError={hasError}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          filled={filled}
          onBlur={() => setTyping(false)}
          onClick={() => setTyping(true)}
        />
      )}
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  )
}
export default PrimaryInput
