import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/home-contact-us-vector1.svg'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  .picture {
    position: absolute;
    top: -380px;
    right: -321px;
    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      top: -219px;
      right: -186px;
      width: 600px;
      height: 600px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      top: -123px;
      right: 0px;
      width: auto;
      height: auto;
    }
  `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      height: 600px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 25px;
    }
  `}
`

export const Amoeba = styled(Amoeba1)`
  position: absolute;
  top: 62px;
  left: 0;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100px;
      height: 200px;
      left: -15px;
      top: 40px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 74px;
      height: 150px;
      left: -15px;
      top: 40px;
    }
  `}
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.tertiaryMedium};
  margin-top: 109px;
  margin-bottom: 30px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 500px;
      text-align: center;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3};
      width: 300px;
      text-align: center;
      margin-top: 160px;
    }
  `}
`

export const Description = styled.p`
  max-width: 663px;
  ${({ theme }) => theme.fonts.largeText};
  text-align: center;
  color: ${({ theme }) => theme.colors.neutralDarkest};
  margin-bottom: 53px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumText};
    }
  `}
`
